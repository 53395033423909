.cta-bordered {
    display: block;
    padding: 1rem;
    &.full-width {
        width: 100%;
        margin: 0;
    }
    &:hover {
        .cta-inner {
            background: $color-purple;

            p {
                color: $color-white;
            }

            img {
                filter: brightness(0) invert(1);                
            }

            // svg {
            //     * {
            //         fill: $color-white
            //     }
            // }
        }
    }
    .cta-inner {
        position: relative;
        z-index: 2;
        align-items: center;
        justify-content: space-evenly;
        display: flex;
        background: #fff;
        padding: 2.5rem 4.5rem;
        border: 1px solid $color-grey;
        @extend %transition;

        .cta-img {
            max-width: 120px;

            @include breakpoint(S) {
                max-width: none;
            }
            @include breakpoint(XS) {
                max-width: 120px;
            }
        }

        .content {
            padding: 0 4.5rem;
        }
        
        p {
            color: $color-purple;
            @include size-L;
            margin: 0;

            &.buttons {

                .arrow {
                    transform: rotate(90deg);
                    border: 1px solid var(--color-active);
                    border-radius: 100%;
                    width: 56px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: center;                    
                }
            }
        }
    }
    .bg-wrapper {
        position: absolute;
        width: 100%;
        overflow: hidden;
        height: 100%;
        top: 0;
        left: 0;
        .bg-wrap {
            position: absolute;
            width: 130%;
            height: 320%;
            top: 0;
            background: url(../images/hari-pattern-dark.svg);
            background-size: 5.75%;
            transform: rotate(40deg) scale(2);
            z-index: -1;
        }
    }
    .objfit img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;        
    }
    @include breakpoint(XS) {
        .cta-inner {
            padding: 2.5rem 1.5rem;
            flex-direction: column;

            .content {
                padding: 1.5rem 0;
                text-align: center;
            }
        }
    }
}