.content-border {
    margin-bottom: 6rem;
    border-color: $color-grey;
}
.content-element {
    &.has-bg {
        background-size: contain;
        background-repeat: no-repeat;
    }
    @include breakpoint(S) {
        &.flex {
            display: block;
        }
    }
    position: relative;
}
.has-animation {
    background: #fbfbfb;
}
.has-illustration {
    position: relative;
    &.btm-right,
    &.btm-left {
        padding-bottom: 8rem;

        +.row {
            .img-content {
                padding-top: 0;
            }
        }

        @include breakpoint(XS) {
            padding-bottom: 2rem;
        }
    }
    .content-illustration {
        position: absolute;
        max-width: 16%;
        max-width: 12%;

        img {
            filter: brightness(1.1);
        }
        &.left,
        &.right {
            top: 50%;
            transform: translateY(-50%);
        }
        &.left {
            left: $unit * 10;
        }
        &.right {
            right: $unit * 10;
        }
        &.btm-right {
            right: 0;
            bottom: 0;
        }
        &.btm-left {
            left: 0;
            bottom: 0;
        }

        @include breakpoint(ML) {
            &.left {
                left: $unit * 6;
            }
            &.right {
                right: $unit * 6;
            }
        }
        @include breakpoint(M) {
            display: none;
        }        
    }
}

.content-element {
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
    .content-element-inner {
        text-align: center;
        max-width: 48em;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        &.content-sidebar {
            margin-top: 2.5rem;
            padding-left: 2.5rem;
            @include breakpoint(XS) {
                padding-left: 0;
            }
        }

        .content-element-title {
            @include size-3XL;
        }

        .space-for-art {
            img {
                margin: 0;
            }
        }

        ul {
            text-align: center;
            margin-bottom: 2.5rem;

            li {
                &:before {
                    content: none;
                }
                &:after {
                    content: "";
                    margin: .5rem auto 1.5rem auto;
                    width: 2rem;
                    height: 2px;
                    position: relative;
                    text-align: center;
                    top: calc(50% - 1px);
                    top: 10px;
                    left: 0;
                    background-color: $color-purple;
                    background-color: var(--color-body);
                    display: block;
                }
            }
        }

        .content-wrap {
           // min-height: 8rem;
        }
    }
    img {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .accordion {
        text-align: left;
    }
    @include breakpoint(XS) {
        padding-bottom: 2rem;

        .content-element-inner {
            hr {
                +div {
                    margin-bottom: 1rem;
                }
            }
        }
    }    
}

.centered {
    text-align: center;

    p {
        max-width: 46rem;
        margin-left: auto;
        margin-right: auto;
    }
    ul {
        text-align: left;
    }
}

.sub-content {
    font-weight: bold;
    letter-spacing: 0.125em;
    text-transform: uppercase;
    color: $color-purple;
    font-family: $font-stack-headings;
}
.mono-logo {
    @extend %transition;
    margin-bottom: 1.5rem;
    display: none;
    
    &.hide {
        opacity: 0 !important;
    }
}
.home {
    #row-1 .mono-logo {
        display: block;
    }
}

/* DNM Form */

[id*="dmn-form-"].dmn-form {
  width: 100% !important;
  text-align: center;
  border: none;
  margin-top: 2rem;  

    table tr:hover td {
        background: none;
    }  
    table tr td:hover {
        color: #fff;
        color: var(--color-reverse);
        background-color: #C8AF82;
        background-color: var(--color-accent1);
        .btn-link {
            color: #fff;
            color: var(--color-reverse);        
        } 
    }
    h1 {
        display: none;
    }      
    .main-inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 50px;
        margin-bottom: 50px;
        text-align: left;
        >div {
            width: calc(50% - 1.5rem);
            margin: 0 .75rem;
            margin-bottom: 1.5rem;
        }
        select {
            appearance: none;   
        }      
        select,
        input,
        input[type="text"] {
          width: calc(100% - 2rem);
          box-sizing:border-box;
          border: none;
          height: 45px;
          width: 100%;
          margin: 0;  
        }
        #dmn-date {
            border: 1px solid #D7D7E1;
            border-color: var(--color-accent3);
            box-shadow: none;
            height: 47px;
            width: 100%;
            border-radius: 0;    
        }
        .dropdown:after {
            z-index: 10;
        }
        label {
            font-weight: 500;
            font-style: italic;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            font-family: "Playfair Display",Georgia,serif;
            font-size: 18px;
            color: #043221;
            margin-bottom: 10px;
            text-align: left;
        }
        @include breakpoint(XS) {
            padding-bottom: 0;
            margin-bottom: 0;
            >div {
                width: 100%;
            }
        }
    }    
}

[id*="dmn-form-"].dmn-form .main-inputs .btn.submit {
    letter-spacing: 0.125em;
    font-size: 1rem;
    font-family: "AvenirLTStd","Avenir",-apple-system,BlinkMacSystemFont,Times,"Times New Roman","TimesNewRoman","Baskerville","Georgia",serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: bold;
    
    text-shadow: none;
    position: relative;
    background-image: none;
    border: none;
    border-radius: 0;
    //Primary button default styling
    color: $color-white;
    color: var(--color-reverse);

    background-color: $color-gold;
    background-color: var(--color-accent1);

    padding: 0.75rem 1.5rem;

    //Icon styling
    svg {
        color: $color-white;
        color: var(--color-reverse);
    }

    //Set up focus styling
    &:before {
        content:"";
        position: absolute;
        background-color: $color-purple;
        background-color: var(--color-accent2);
        bottom: 0;
        left: 0;
        right: auto;
        width: 100%;
        height: 0;
    }

    //Hover state
    &:hover {
        &:before {
            height: 100%;
        }
        //Icons on hover
        svg {
            color: $color-white;
            color: var(--color-reverse);
        }
    }

    //Focus state
    &:focus {
        color: $color-active;
        color: var(--color-active);

        &:before {
            opacity: 1;
        }

        svg {
            color: $color-active;
            color: var(--color-active);
        }
    }
    
    //Buttons with icons
    &.icon-right,
    &.icon-left,
    &.icon-both {

        padding: $unit ($unit * 2);

    }

    &.active {
        background: $color-active;
        background: var(--color-active);
        color: $color-white;
        color: var(--color-reverse);
    }
}

.dmn-form .powered-by {
  display: none;
}