.icon-list {
    &.slick-slider {
        text-align: center;

        li {
            margin: 0;
        }
        p {
            opacity: 0;
            font-size:16px;
            font-weight: bold;
            letter-spacing: .125em;
            text-transform: uppercase;
            max-width: 60%;
            margin: auto;
        }
        svg {
            margin-right: 0;
            width: 6rem;
            height: 6rem;
            transform: scale(0.5);
            opacity: 0.25;
        }
        .slick-active {
            p {
                opacity: 1;
            }
            svg {
                transform: scale(1);
                opacity: 1;
            }
        }
    }
}

.icon-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;
  
    &.left-aligned {
        justify-content: flex-start;
    }
    li {
        display: flex;
        align-items: center;
        margin: 0 1.5rem;
        padding: 1em 0;

        &.half {
            width: 50%;
            margin: 0;

            &:nth-child(odd) {
                padding-right: 0.25rem;
            }
            &:nth-child(even) {
                padding-left: 0.25rem;
            }
        }
        &.mob-full {
            @include breakpoint(XS) {
                width: 100%;

                &:nth-child(odd) {
                    padding-right: 0;
                }
                &:nth-child(even) {
                    padding-left: 0;
                }
            }
        }
        &:before {
            display: none;
        }
        svg {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 1rem;
            flex-shrink: 0;
            color: $color-active;
            color: var(--color-active);
            @extend %transition;
        }
        .icon-img {
            margin-right: 2em;
            float: left;
            border: 1px solid $color-purple;
            background: #fff;
            height: 6.5em;
            width: 6.5em;
            display: flex;
            justify-content: center;
            align-items: center;
    
            svg,
            img {
                width: 3.5em;
                height: 3.5em;
            }
        }
        p {
            margin: 0;
            @extend %transition;
        }
    }
    &.minor {
        li {
            svg {
                width: 2rem;
                height: 2rem;
                color: $color-purple;
                color: var(--color-body);
            }
        }
    }
    &.carousel {
        overflow: hidden;
        display: block;
        margin: 0;
        padding-top: 3rem;
        padding-bottom: 4rem;
    
        @include breakpoint(XS) {
            padding-left: 0;
            padding-right: 0;
        }
        li {
            padding: 0 1em;
            overflow: hidden;
    
            .icon-img {
                float: none;
                position: relative;
                margin: 0 auto 1.625em auto;
    
                @include breakpoint(S) {
                    margin: 0 auto 2em auto;
                }
                &:before,
                &:after {
                    content: "";
                    height: 1px;
                    background: $color-gold;
                    width: 10em;
                    position: absolute;
                    top: 50%;
                }
                &:before {
                    right: calc(100% + 1.5rem);
                }
                &:after {
                    left: calc(100% + 1.5rem);
                }
            }
            p,
            svg {
                margin: 0;
            }
            .icon-content {
                text-align: center;
            }
            &:first-child {
                .icon-img {
                    &:before {
                        display: none;
                    }
                }
            }
            &:last-child {
                .icon-img {
                    &:after {
                        display: none;
                    }
                }
            }
        }
        .slider-prev,
        .slider-next {
            top: auto;
            bottom: -1rem;
            background: $color-purple;

            svg {
                display: block;
            }
        }
        .slider-prev {
            left: calc(50% - 3rem);
        }
        .slider-next {
            right: calc(50% - 3rem);
        }
    }
    &.icon-top-centered {
        li {
            width: 25%;
            padding: 0;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @include breakpoint(S) {
                width: 50%;
                padding-bottom: 2rem;
            }
        }
        svg {
            margin: 0 auto 0.5rem auto;
            width: 2rem;
            height: 2rem;
        }
        .icon-img {
            width: 4.5rem;
            height: 4.5rem;
        }
        &.larger-icon {
            .icon-img {
                width: 5.625rem;
                height: 5.625rem;

                svg {
                    width: 3rem;
                    height: 3rem;
                }
            }
        }
    }
    &.centered {
        margin-left: 0;
        margin-right: 0;
        justify-content: center;

        li {
            padding: 0 1rem;

            @include breakpoint(XS) {
                padding: 1rem;
            }
        }
    }
}

.icon-list-dots-wrapper {
    margin: 0;
    padding-top: 2rem;
    display: flex;
    align-items: center;

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon-list-control {
        padding: 0 0.25rem;
        color: #4A4F54;
        color: var(--color-body);

        &:first-child {
            padding-left: 0;
        }
        svg {
            display: block;
        }
    }
    .icon-list-dots {
        .slick-dots {
            position: static;
        }
    }
}

// .icon-list-wrapper {
//     @include breakpoint(XS) {
//         margin-bottom: 1rem;
//     }
// }

.icon-list-dots-wrapper {
    justify-content: center;
    padding-top: 1rem;
    display: none !important;

    @include breakpoint(XS) {
        // display: flex !important;
    }
}

//inherit style from gallery carousel
.icon-controls {
    display: none;

    @include breakpoint(XS) {
        display: flex;
    }

    &:hover {

        svg {

            rect {
                stroke: $color-gold;
            }
            path {
                fill: $color-gold;
            }
        }
    }    
}