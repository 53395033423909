.padded-content-container {
    padding-right: 12.5rem;
}
.padded-content-carousel {
    display: flex;
    position: relative;
    padding-top: 3rem;
    padding-bottom: 6rem;
    margin-bottom: 0.5rem;
    margin-top: 5rem;

    @include breakpoint(XS) {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 2.5rem;
        margin-bottom: 3rem;
        flex-direction: column;
    }
    .content {
        width: 45%;
        padding: 0 0 0 7.25em;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(M) {
            padding: 0 0 0 5em;
        }
        @include breakpoint(S) {
            padding: 0 0 0 3em;
        }
        @include breakpoint(XS) {
            padding: 5rem 0 2rem 0;
            margin: 0;
            width: 100%;
            text-align: center;
            flex-basis: auto;

            hr {
                margin-left: auto;
                margin-right: auto;
            }
            .buttons {
                justify-content: center;
                margin-bottom: 0;
                flex-direction: column;
                width: 75%;
            }
        }
        &.top-align {
            padding-top: 1em;
            padding-bottom: 7.5em;

            @include breakpoint(XS) {
                padding-bottom: 2em;
            }
        }
        .content-inner {
            width: 100%;
            max-width: 32rem;
            display: flex;
            flex-direction: column;

            .buttons {
                .button {
                    margin: 0.5rem 1.5rem 0.5rem 0;
                    &.secondary {
                        border: 2px solid rgba(20,30,70,.25);
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
                @include breakpoint(XS) {
                    .button {
                        margin-right: 0;
                    }
                }
            }

            .heading {
                position: relative;
        
                .title-illustration {
                    position: absolute;
                    right: -10%;
                    width: 55%;
                    bottom: -2rem;
                    z-index: -1;

                    img {
                        margin-left: auto;
                        filter: brightness(1.1);
                    }
                }
            }            

            @include breakpoint(XS) {
                padding-top: 0;
                align-items: center;
                max-width: none;

                > .button {
                    margin-top: -1.5rem;
                }

                .heading {

                    .title-illustration {
                        position: static;
                        width: auto;
                        margin-bottom: 2rem;
                    }
                }
            }          
        }
    }
    .img {
        width: 35%;
        position: relative;
        min-height: 30em;
        
        @include breakpoint(XS) {
            min-height: 20em;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            margin-top: -4rem;

            .slick-dots {
                display: none !important;
            }
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        .slick-list,
        .slick-track {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }
    .image-carousel-controls {
        padding-left: 5rem;

        @include breakpoint(ML) {
            padding-left: 2rem;
        }
        @include breakpoint(XS) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }
    &.text-image {
        .image-carousel-controls {
            padding-left: 0;
            padding-right: 5rem;

            @include breakpoint(ML) {
                padding-right: 2rem;
            }
            @include breakpoint(XS) {
                padding-left: 1.25rem;
                padding-right: 1.25rem;
            }
        }
        .content {
            order: 1;
            padding: 0 5.25em 0 0;

            @include breakpoint(M) {
                padding: 0 5em 0 0;
            }
            @include breakpoint(S) {
                padding: 0 3em 0 0;
            }
            @include breakpoint(XS) {
                padding: 5rem 0 2rem 0;
                display: block;
                margin: 0;
                order: unset;
            }
        }
        .img {
            order: 2;

            @include breakpoint(XS) {
                order: unset;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                margin-top: -4rem;
            }

            .slick-list {
                width: calc(100% + 3.5rem);
                right: -3.5rem;

                @include breakpoint(XS) {
                    right: 0;
                    width: 100%;
                }
            }
        }
    }
    &.mob-img-bottom {
        @include breakpoint(XS) {
            .content {
                order: 1;
            }
            .img {
                order: 2;
            }
        }
    }
}

.content-carousel-controls {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    width: 100%;
    padding-right: 1.5rem;

    a {
        padding: 1rem;

        svg {
            display: block;
        }
    }
    .caption {
        span {
            padding-right: 1rem;
        }
    }
    @include breakpoint(XS) {
        .caption {
            position: absolute;
            left: 25%;
            width: 50%;
            text-align: center;
        }
        .controls {
            width: 100%;
            justify-content: space-between;
        }
    }
}