.amenities-tabs {
    padding-top: 3rem;

    .slick-arrow {
        padding: 0;
    }
    .amenities-carousels {
        padding-top: 1rem;
    }
    .amenities-carousel-left {
        padding-top: 5rem;
        padding-bottom: 2rem;

        @include breakpoint(XS) {
            padding-top: 1.5rem;
            text-align: center;

            hr {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .amenities-carousel {
        display: flex;

        @include breakpoint(XS) {
            flex-direction: column;
        }
    }
    .amenities-carousel-img-text-wrapper {
        width: 50%;
        padding-left: 9rem;
        padding-right: 5rem;

        @include breakpoint(XS) {
            width: 100%;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            order: 2;

            .slider-prev {
                display: none !important;
            }
        }
        .slider-prev {
            left: -6rem;
        }
    }
    .amenities-carousel-img-carousel-wrapper {
        width: 50%;
        position: relative;

        @include breakpoint(XS) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            height: 13rem;
            order: 1;

            .slick-arrow {
                svg {
                    width: 28px;
                    height: 28px;
                }
            }
        }
        .slick-slide {
            width: calc(50vw - 6rem);
            margin-right: 2rem;

            @include breakpoint(XS) {
                width: calc(100vw - 6rem);
                margin-right: 0.375rem;
                margin-left: 0.375rem;
            }
        }
        .slick-prev {
            display: none !important;

            @include breakpoint(XS) {
                display: block !important;
            }
        }
        .amenities-carousel-img-carousel {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
        }
        .slick-list,
        .slick-track,
        img {
            height: 100%;
        }
    }
}