//Brand Colours
$color-body: #141E46;
$color-purple: #141E46;
$color-gold: #C8AF82;
$color-grey: #D7D7E1;
$color-lightgrey: #E5E5E5;

$color-white: #fff;
$color-green: #7CAE7A;
$color-yellow: #E59500;
$color-red: #F0544F;

$color-active: $color-gold;
$color-success: $color-green;
$color-error: $color-red;
$color-warning: $color-yellow;