html.cssanimations {
  .fade-in,
  .fade-in-up,
  .fade-in-down,
  .fade-in-left,
  .fade-in-right {
    opacity: 0;
  }
}

.fade-in {
  animation: fade-in 0.5s ease-out;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}

.bob {
  animation: bob 3s ease-out infinite;
}

.bob-right {
  animation: bob-right 3s ease-out infinite;
}

.fade-in-right {
  opacity: 0;
  &.fade-in {
    animation: fade-in-right 1.2s cubic-bezier(0, 0, 0.13, 0.98);
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}
.fade-in-left {
  opacity: 0;
  &.fade-in {
    animation: fade-in-left 1.2s cubic-bezier(0, 0, 0.13, 0.98);
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}
.fade-in-up {
  opacity: 0;
  &.fade-in {
    animation: fade-in-up 1.2s cubic-bezier(0, 0, 0.13, 0.98);
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}
.fade-in-down {
  opacity: 0;
  &.fade-in {
    animation: fade-in-down 1.2s cubic-bezier(0, 0, 0.13, 0.98);
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform;
}