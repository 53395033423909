.instagram-element {
    position: relative;

    .instagram-controls {
        position: absolute;
        right: 6rem;
        bottom: 0;

        a {
            padding: 1rem;
            padding-bottom: 0;
        }
    }

    @include breakpoint(XS) {
        padding-top: 2rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        .rhythm {
            margin-bottom: 0;
        }
        h2 {
            justify-content: center;
        }
        hr {
            margin-left: auto;
            margin-right: auto;
        }
        p {
            text-align: center;
        }

        .instagram-controls {
            display: none;
        }
    }
}

.instagram-feed {
    margin-top: 3rem;
    display: flex;

    .insta-slide {
        margin: 0 2.5rem;
        max-width: 400px;
        max-height: 400px;
    }

    @include breakpoint(S) {
        // flex-wrap: wrap;
        margin-left: 0rem;
        margin-right: 0rem;
        margin-top: 0;
        margin-bottom: 3rem;
        padding-left: 3.5rem;
    }
    @include breakpoint(XS) {
        .insta-slide {
            margin: 0 0.875rem;
            // width: calc(100vw - 8rem) !important;
        }
    }
}

.slick-slide .insta-img,
.insta-img {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    position: relative;
    width: calc(100% - 4rem);
    height: 17.5rem;
    margin: 0 0.25rem;

    width: 85vw;
    max-width: 18rem;
    height: 0;
    padding-bottom: 100%;
    margin: 0;    

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    @include breakpoint(L) {
        // height: 12rem;
    }
    @include breakpoint(M) {
        // height: 12rem;
        // width: calc(20% - 0.5rem);

        // &:last-child {
        //     display: none;
        // }
    }
    @include breakpoint(S) {
        // height: 14rem;
        // width: calc(33% - 0.5rem);
        // margin: 0.25rem;
        // width: calc(100% - 1rem);

        &:last-child {
            display: block;
        }
    }
    @include breakpoint(XS) {
        // width: calc(50% - 0.5rem);
        // height: 17rem;
        // width: auto;
    }
    .insta-caption {
        position: absolute;
        opacity: 0;
        @extend %transition;
        background: rgba(0,0,0, 0.6);
        font-size: 0.75rem;
        text-align: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        align-items: center;

        p {
            font-size: 1rem;
            margin: 0;
            padding: 0.5rem;
            width: 100%;
            color: $color-white;
        }
    }
    &:hover {
        .insta-caption {
            opacity: 1;
        }
    }
    .insta-img-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}