.menu-container {
    margin-bottom: 5rem;
}
.menu-blocks {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    margin-left: -1rem;
    margin-right: -1rem;
    min-height: 25em;
    align-items: center;
    @include breakpoint(S) {
        flex-direction: row;
    }
    @include breakpoint(XS) {
        margin-left: 0;
        margin-right: 0;
    }
}

.menu-blocks .cta {
    margin: 1rem;
    min-height: 18em;
    max-height: 18em;
    width: calc(33.333% - 2rem - 1px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    overflow: hidden;
    border-radius: 2px;
    @extend %nav-transition;

    // &:hover {
    //     min-height: 22em;
    //     box-shadow: 0px 4px 8px rgba($color-purple, 0.15);

    //     .cta-inner {
    //         height: 22em;

    //         h3 {
                
    //             &:before {
    //                 opacity: 1;
    //             }
    //         }
    //     }
    // }

    @include breakpoint(S) {
        width: calc(50% - 2rem - 1px);
    }
    @include breakpoint(XS) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        min-height: 20em;
    }
    &.single {
        width: 100%;
        min-height: 15rem;
        margin: 0;
    }
    &.half {
        width: calc(50% - 2rem - 1px);

        @include breakpoint(XS) {
            width: 100%;
        }
    }
    &.third {
        width: calc(33.333% - 2rem - 1px);

        @include breakpoint(S) {
            width: calc(50% - 2rem - 1px);
        }
        @include breakpoint(XS) {
            width: 100%;
        }
    }
    &.fourth {
        width: calc(25% - 2rem - 1px);

        @include breakpoint(S) {
            width: calc(50% - 2rem - 1px);
        }
        @include breakpoint(XS) {
            width: 100%;
        }
    }    
    &.short {
        height: 20em;

        .cta-inner {
            padding: 0;
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: $color-white;
    }
    .top-label {
        position: absolute;
        top: 2rem;
        left: 0;
        width: 100%;
        z-index: 3;
        letter-spacing: 0.065em;
        text-align: center;
        max-width: none;
        text-transform: uppercase;

        @include breakpoint(XS) {
            text-align: right;
            left: auto;
            width: auto;
            right: 1.5rem;
        }
    }
    .cta-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 3;
        width: 100%;
        height: 18em;
        padding: 3rem;
        @extend %nav-transition;

        .button {
            opacity: 0;
            position: absolute;
            bottom: 50%;
            color: $color-purple;
            transform: translateY(50%);
            @extend %transition;

            &:before {
                background-color: #C8AF82;
                background-color: var(--color-accent1);                
            }
        }
        p:last-child {
            margin-bottom: 0;
        }
        &:before,
        &:after {
            display: none;
            content: "";
            position: absolute;
            left: 50%;
            width: 1px;
            height: 100%;
            @extend %nav-transition;
            background: rgba(#fff, 0.5);
        }
        &:before {
            top: calc(100% + 1rem);
        }
        &:after {
            bottom: calc(100% + 1rem);
        }
        &.no-lines {
            &:before,
            &:after {
                display: none;
            }
        }
        h3 {
            position: absolute;
            top: 45%;
            max-width: 95%;
            color: $color-purple;
            text-transform: none;
            z-index: 2;
            @extend %transition;
            padding-bottom: 10px;
            letter-spacing: normal;
            width: auto;
            font-family: $font-stack-primary;

            &:before {
                content: '';
                position: absolute;
                width: 50%;
                background-color: #C8AF82;
                background-color: var(--color-accent1);
                height: 2px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);                
                opacity: 0;
            }
        }        
    }
    @include breakpoint(XS-up) {
        &:hover {
            min-height: 23em;
            box-shadow: 0px 4px 8px rgba($color-purple, 0.15);
        
            .cta-inner {
                height: 22em; 
                h3 {
                    top: 2rem;
                    &:before {
                        opacity: 1;
                    }
                }
                .button {
                    opacity: 1;
                    bottom: 2rem;
                    transform: none;
                }
            }
        }
    }   
    h2,
    h3,
    h4,
    h5 {
        hyphens: auto;
        width: 100%;
        font-weight: 500;
    }
    p {
        max-width: 28em;
        width: 100%;
    }
    .date {
        margin-bottom: 0.5rem;
    }
    .tagline {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding-top: 1rem;
        text-align: center;
        z-index: 3;
        color: $color-purple;
        @extend %nav-transition;

        @include breakpoint(S) {
            display: none;
        }
    }
    .cta-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        transform: translate(-50%,-50%);
        max-height: 110px;
        opacity: .1;
        z-index: 1;
        @extend %nav-transition;
    }
    &:hover,
    &.hover {
        .tagline {
            opacity: 0;
        }
        img {
            opacity: 1;
        }
        .cta-inner {
            &:before {
                transform: translateY(1rem);
            }
            &:after {
                transform: translateY(-1rem);
            }
        }
    }
    &.no-img {
        border: 2px solid $color-gold;
        color: $color-purple;
        background: #fff;

        .tagline,
        h2,
        h3,
        h4,
        h5,
        p,
        .button {
            color: $color-purple;
        }
        hr {
            background: $color-gold;
        }
        &:before {
            display: none;
        }
        &:hover {
            background: mix(white, $color-gold, 90%);

            .button {
                color: $color-gold;
            }
        }
    }
    .roundel {
        position: absolute;
        z-index: 5;
        top: 1rem;
        right: 1rem;
        color: $color-white;
        color: var(--color-body);
        background: $color-gold;
        background: var(--color-active);
        width: 5.625rem;
        height: 5.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        padding: 0.5rem;

        @include breakpoint(XS) {
            top: -1.5rem;
        }
    }
    &.has-base-content {
        @include breakpoint(XS) {
            min-height: 12rem !important;
            overflow: visible;

            &:hover {
                img {
                    transform: scale(1);
                }
            }
            .base-content {
                position: static;
            }
        }
    }
    &.has-buttons {
        @include breakpoint(XS) {
            text-align: left;
            min-height: 12rem !important;
            display: block;

            .base-content,
            .base-buttons,
            .base-buttons.stuck {
                position: relative;
            }
            .cta-inner {
                padding: 2rem 1.5rem 1.5rem 1.5rem;
                position: relative;
                display: block;
            }
        }
    }
    .base-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 4;
        text-align: left;

        .inner {
            padding: 2rem 1.5rem 1rem 1.5rem;
        }
    }
    .base-buttons {
        border-top: 1px solid $color-white;

        a {
            flex-grow: 1;
            flex-basis: 0;
            width: 100%;
            margin: 0;
            border-right: 1px solid $color-white;

            &:last-child {
                border-right: 0;
            }
            &:hover {
                color: $color-active;
                color: var(--color-active);
            }
        }
        &.stuck {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 3;
        }
    }
    
    @include breakpoint(XS) {
        box-shadow: 0px 4px 8px rgba($color-purple, 0.15);
        img {
            opacity: 1;
            height: 45%;
        }
        .cta-inner {
            height: 20em;

            h3 {
                top: 2.25rem;
                &:before {
                    opacity: 1;
                }
            }
            .button {
                opacity: 1;
                bottom: 2.25rem;
                transform: none;
            }
        }
    }
}

.cta-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -1rem;
    margin-right: -1rem;

    &.justify-start {
        justify-content: flex-start;
    }
    &.justify-center {
        justify-content: center;
    }
    @include breakpoint(XS) {
        margin-left: 0;
        margin-right: 0;
    }
    .cta {
        flex-grow: 0;

        @include breakpoint(M) {
            min-height: 22em;
        }
        .cta-inner {
            width: 100%;
        }
    }
    &.feat-first {
        display: block;

        .cta {
            float: left;
            height: 18em;
            min-height: 0;
            
            &:first-child {
                width: calc(50% - 0.5em);
                height: calc(37em - 0.5px);

                @include breakpoint(XS) {
                    width: 100%;
                    height: 18em;

                    .diamond {
                        display: none;
                    }
                    h2 {
                        margin: 0;
                        font-size: 1.357em;
                        line-height: 1.289473684210526;
                    }
                }
            }
        }
    }
}