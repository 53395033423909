.post-carousel-wrapper {
    position: relative;
    overflow: hidden;

    &:hover{
        .post-controls{
            svg{
                opacity: 1;
            }
        }
    }

    @include breakpoint(XS) {
        margin-bottom: 2rem;
    }

    .buttons {
        margin-bottom: 2.5rem;
    }

    >img {
        position: absolute;
        top: 11.5rem;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;        
    }

    .bg-wrapper {
        position: absolute;
        width: 100%;
        overflow: hidden;
        height: 100%;
        top: 11.5rem;
        z-index: -1;

        .bg-wrap {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: url(../images/hari-pattern.svg), rgba(20, 30, 70, 0.125);
            background-size: 5.75%;
            transform: rotate( 40deg) scale(2.2);
            z-index: -1;
            right: -200px;
        }
        @include breakpoint(S) {
            .bg-wrap {
                right: 0;
            }
        }
    }

    &.theme--grey.has-bg,
    &.theme--blue.has-bg {
        background: none !important;
        >img {
            background: var(--color-bg);
        }
    }
    &.theme--blue.has-bg {
        .bg-wrapper {
            .bg-wrap {
                background-image: url(../images/hari-pattern.svg);
            }
        }
    }
    &.theme--grey.has-bg {
        .bg-wrapper {
            .bg-wrap {
                background-image: url(../images/hari-pattern-dark.svg);
            }
        }        
        .post-details {
            * {
                color: $color-body;
            }
        }
    }
}

.post-details {
    padding: 0 12rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    text-align: center;

    * {
        color: $color-body;
        color: var(--color-body);
    }

    @include breakpoint(XS) {
        padding: 0;
        padding-top: 1rem;
        width: calc(100vw - 10rem);
        margin: auto;
        flex-direction: column;

        .caption {
            order: 2;
        }

        .slides {
            order: 1;
        }
    }
}

.post-controls{
    position: absolute;
    top: 0rem;
    height: 100%;
    max-height: 24.5em;
    width: 19.5%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    color: var(--color-reverse);

    @include breakpoint(ML) {
        width: 17.75%;
    }

    @include breakpoint(M) {
        width: 12%;
    }

    @include breakpoint(S) {
        width: 2rem;
    }
    @include breakpoint(XS) {
        width: 2.3rem;
        max-height: 16rem;

        svg {
            display: none;
        }
    }    

    svg {
        position: relative;
        z-index: 3;
        opacity: 0;
        @extend %transition;
        * {
            @extend %transition;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: rgba(#000, 0.6);
        @extend %transition;
        opacity: 0;
    }

    &:hover {
        &:before {
            opacity: 0;
        }

        svg {
            opacity: 1;
        }
    }

    // svg {
    //     display: none;
    // }

    &.post-prev {
        left: 0;

        .post-controls-inner {
            &:before {
                right: 100%;
            }
        }
    }
    &.post-next {
        right: 0;

        svg {
            transform: rotate(180deg);
        }

        .post-controls-inner {
            &:before {
                left: 100%;
            }
        }
    }
    .post-controls-inner {
        width: 5.5rem;
        height: 5.5rem;
        border-radius: 100%;
        border: 2px solid #fff;
        border: 2px solid var(--color-reverse);
        position: relative;
        @extend %transition;
        display: none;

        @include breakpoint(XS) {
            display: none;
        }
        &:before {
            content: "";
            height: 2px;
            width: 4rem;
            right: 100%;
            background: #fff;
            background: var(--color-reverse);
            top: calc(50% - 1px);
            position: absolute;
        }
        .slideno {
            position: absolute;
            top: 1rem;
            left: 1rem;
        }
        .totalslides {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
        .line {
            position: absolute;
            height: 2px;
            width: 3.5rem;
            background: #fff;
            background: var(--color-reverse);
            bottom: 2.5rem;
            left: 1rem;
            transform: rotate(-45deg);
        }
    }
    &:hover {
        color: #fff;
        color: var(--color-reverse);

        .post-controls-inner {
            background: $color-gold;
        }
    }
}

.post-carousel {
    .slick-track {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        padding-top: 0;
    }
    .post-carousel-slide {
        position: relative;
        min-height: 24.5em;
        max-height: 83vh;
        margin: 0 2.5rem;
        width: calc(100vw - 24rem);
        transform: scale(1);
        @extend %transition;

        width: 80vw;
        max-width: 44rem;        

        // &.slick-active {
        //     max-height: 90vh;
        // }

        // &.slick-center {
        //     transform: scale(1.05);
        // }        

        @include breakpoint(M) {
            height: 24rem;
            margin: 0 1.5rem;
        }
        @include breakpoint(S) {
            height: 16rem;
            min-height: 1px;
            width: calc(100vw - 8rem);
            margin: 0 .5rem;
        }
        .post-image {
            padding-top: 60%;
            position: relative;
            z-index: 2;
        }
        .post-dates {
            position: absolute;
            left: 0;
            top: 0;
            background: $color-body;
            color: $color-gold;
            padding: 1.25rem 1.15rem;
            display: flex;
            align-items: center;
            justify-items: center;
            z-index: 9;
            flex-direction: column;
            opacity: 0;

            * {
                color: $color-gold;
            }
            h4 {
            }
            p {
                letter-spacing: 0px;
            }
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: rgba(#000, 0.6);
            @extend %transition;
            opacity: 1;
        }
        &:hover {
            &:not(.slick-active) {
                &:before {
                    opacity: 1;
                }                
            }
        }
        &.slick-active {
            &:before {
                opacity: 0;
            }
            .post-dates {
                opacity: 1;
            }
        }
        &.has-caption {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 81.25%, rgba(0, 0, 0, 0.75) 100%);
            }
        }
        .caption {
            position: absolute;
            bottom: 2.25rem;
            left: 0;
            width: 100%;
            padding: 0 1rem;
            text-align: center;
            z-index: 3;
            opacity: 0;
            @extend %nav-transition;
            transform: translateY(1rem);

            @include breakpoint(XS) {
                bottom: 0.875rem;
            }
        }
    }
    .slick-active {
        .caption {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .slick-arrow {
        top: 0;
        transform: none;
        height: 100%;
        width: 30%;
        padding: 0;
        display: flex !important;
        align-items: center;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            @extend %transition;
        }
        svg {
            position: relative;
            z-index: 2;
        }
        &.slider-prev {
            left: 0;

            &:after {
                left: -3rem;
                background: linear-gradient(to right, rgba(0,0,0,0.1) 0%,rgba(125,185,232,0) 100%);
            }
            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
        &.slider-next {
            right: 0;
            justify-content: flex-end;

            &:after {
                right: -3rem;
                background: linear-gradient(to left, rgba(0,0,0,0.1) 0%,rgba(125,185,232,0) 100%);
            }
            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}