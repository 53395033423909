.img-content-column {
    display: flex;
    position: relative;
    padding-top: 3rem;
    padding-bottom: 6.5rem;

    .col {
        margin-bottom: 2.75rem;
    }
    @include breakpoint(S) {
        padding-top: 0;
        padding-bottom: 0;
        flex-direction: column;

        >.col {
            margin-bottom: 2.5rem;
        }
    }
    .content {
        width: 45%;
        padding: 0 0 0 7.25em;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(M) {
            padding: 0 0 0 5em;
        }
        @include breakpoint(S) {
            padding: 0 0 0 3em;
        }
        @include breakpoint(XS) {
            padding: 6.5rem 0 2rem 0;
            margin: 0;
            width: 100%;
            text-align: center;
            flex-basis: auto;

            hr {
                margin-left: auto;
                margin-right: auto;
            }
            .buttons {
                justify-content: center;
                margin-bottom: 0;
            }
        }
        &.top-align {
            padding-top: 1em;
            padding-bottom: 7.5em;

            @include breakpoint(XS) {
                padding-bottom: 2em;
            }
        }
        .content-inner {
            width: 100%;
            max-width: 32rem;
            display: flex;
            flex-direction: column;

            .heading {
                position: relative;
        
                .title-illustration {
                    position: absolute;
                    right: -10%;
                    width: 55%;
                    bottom: -2rem;
                    z-index: -1;

                    img {
                        margin-left: auto;
                        filter: brightness(1.1);
                    }
                }
            }            

            @include breakpoint(XS) {
                padding-top: 0;
                align-items: center;
                max-width: none;

                hr {
                    +div {
                        margin-bottom: 1rem;
                    }
                }

                > .button {
                    margin-top: -1.5rem;
                }

                .heading {

                    .title-illustration {
                        position: static;
                        width: 75%;
                        margin: auto;
                        margin-bottom: 2rem;
                    }
                }
            }          
        }
    }
    .content-element {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 2rem;
        hr {
            margin-bottom: 1.5rem;
        }
        @include breakpoint(XS) {
            .content-element-inner {
                hr {
                    +div {
                        margin-bottom: 3rem;
                    }
                }
            }
        }
    }
    .img {
        // width: 55%;
        position: relative;
        min-height: 24.5em;
        display: block;
        overflow: hidden;

        @include breakpoint(M) {
            min-height: 20em;
        }
        
        @include breakpoint(XS) {
            min-height: 20em;
            margin-left: -2.5rem;
            margin-right: -2.5rem;
            width: calc(100% + 5rem);

            .slick-dots {
                display: none !important;
            }
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            @extend %transition;
        }
        .slick-list,
        .slick-track {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }
    .image-carousel-controls {
        padding-left: 5rem;

        @include breakpoint(ML) {
            padding-left: 2rem;
        }
        @include breakpoint(XS) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }
    &.text-image {
        .image-carousel-controls {
            padding-left: 0;
            padding-right: 5rem;

            @include breakpoint(ML) {
                padding-right: 2rem;
            }
            @include breakpoint(XS) {
                padding-left: 1.25rem;
                padding-right: 1.25rem;
            }
        }
        .content {
            order: 1;
            padding: 0 5.25em 0 0;

            @include breakpoint(M) {
                padding: 0 5em 0 0;
            }
            @include breakpoint(S) {
                padding: 0 3em 0 0;
            }
            @include breakpoint(XS) {
                padding: 5rem 0 2rem 0;
                display: block;
                margin: 0;
                order: unset;
            }
        }
        .img {
            order: 2;

            @include breakpoint(XS) {
                order: unset;
                margin-left: -2.5rem;
                margin-right: -2.5rem;
                width: calc(100% + 5rem);
            }
        }
    }
    &.mob-img-bottom {
        @include breakpoint(XS) {
            .content {
                order: 1;
            }
            .img {
                order: 2;
            }
        }
    }
}

.image-carousel-controls {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    width: 100%;

    a {
        padding: 1rem;

        svg {
            display: block;
        }
    }
    .caption {
        span {
            padding-right: 1rem;
        }
        @include breakpoint(XS) {
            display: flex;
            justify-content: center;
            flex-direction: column;            
            span {
                padding: 0;
                &:first-of-type {
                    order: 2;
                }
                &:last-of-type {
                    order: 1;
                }                
            }
        }
    }
    @include breakpoint(XS) {
        .caption {
            position: absolute;
            left: 25%;
            width: 50%;
            text-align: center;
        }
        .controls {
            width: 100%;
            justify-content: space-between;
        }
    }
}

.js-image-carousel {

    .slick-track {

        .img-slide {
            @include breakpoint(XS) {
                
            }
        }
    }
}