.builder-elements {
    width: 100%;
    position: relative;
    padding-top: 2rem;

    > .row {
        &:last-child {
            padding-bottom: 0;
        }
        &.no-margin {
            padding-bottom: 0;

            @include breakpoint(XS) {
                padding-bottom: 0;
            }
        }
        &.card-grid-container {
            padding-bottom: 0.625rem;

            @include breakpoint(XS) {
                padding-bottom: 0;
            }  
        }
        &.centered-content-row {
            padding-bottom: 2rem;
        }
    }

    .spacing {
        padding-top: 4rem;
        padding-bottom: 4rem;

        @include breakpoint(XS) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        &.no-spacing-mob {
            @include breakpoint(XS) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        &.no-spacing-top{
            padding-top: 0 !important;
        }
    }
    .spacing-top {
        padding-top: 4rem;

        @include breakpoint(XS) {
            padding-top: 2rem;
        }
    }
    .spacing-bottom {
        padding-bottom: 4rem;

        @include breakpoint(XS) {
            padding-bottom: 2rem;
        }
    }
    .page-404 {
    .content-element {
        @include breakpoint(L) {
        display: flex;
        flex-direction: column-reverse;
    }
    }
     .image-404 {
        position: absolute;
        right: 0;
        top: 0;
        @include breakpoint(L) {
            position: relative;
        }
        img {
            margin-inline: auto;
        }
    }
    }
}