.in-page-nav {
    border-bottom: 1px solid rgba($color-purple, 0.25);
    display: flex;
    justify-content: center;

    @include breakpoint(XS) {
        display: none;
    }
    a {
        color: $color-purple;
        color: var(--color-body);
        padding: 1.125rem 0rem;
        margin: 0 2rem;
        padding-bottom: .25rem;
        border-bottom: 2px solid transparent;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.125em;
        font-family: $font-stack-headings;

        &:after {
            content: "";
            position: absolute;
            bottom: -1.5px;
            width: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 3px;
            background: $color-active;
            background: var(--color-active);
            @extend %transition;
        }
        &.active,
        &:hover {
            color: $color-active;
            color: var(--color-active);
            border-bottom: 2px solid $color-active;

            &:after {
                width: 100%;
            }
        }
    }
}