.cta {
    &.full-width {
        width: 100%;
        margin: 0;
        min-height: 32rem;

        @include breakpoint(XS) {
            min-height: 22em;
        }
        >a {
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            left: 0;

            .cta-inner {

                h3 {
    
                    +hr {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
        .cta-inner {

            h3 {

                +hr {
                    border-bottom-color: $color-white;
                }
            }

            .button {
                opacity: 1;

                &:before {
                    background-color: $color-white;
                }
            }
        }
    }
}