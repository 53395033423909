.gallery-carousel-wrapper {
    position: relative;
    overflow: hidden;

    @include breakpoint(XS) {
        margin-bottom: 2rem;
    }
}

.gallery-details {
    padding: 0 12rem;
    padding-top: 1.5rem;

    * {
        color: $color-body;
    }

    @include breakpoint(XS) {
        padding: 0;
        padding-top: 1rem;
        width: calc(100vw - 10rem);
        margin: auto;
        flex-direction: column;

        .caption {
            order: 2;
            text-align: center;
        }

        .slides {
            order: 1;
        }
    }

    p {
        color: $color-body;
    }
}

.gallery-controls,
.icon-controls {
    position: absolute;
    top: 0;
    height: 100%;
    width: 9rem;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    color: var(--color-reverse);

    svg {
        * {
            @extend %transition;
        }
    }

    &:hover {

        svg {

            rect {
                stroke: $color-white;
            }
            path {
                fill: $color-white;
            }
        }
    }

    // svg {
    //     display: none;
    // }
    @include breakpoint(XS) {
        width: 1.75rem;

        svg {
            display: block;
            display: none;
        }
    }
    &.gallery-prev {
        left: 0;

        .gallery-controls-inner {
            &:before {
                right: 100%;
            }
        }
    }
    &.gallery-next {
        right: 0;

        svg {
            transform: rotate(180deg);
        }

        .gallery-controls-inner {
            &:before {
                left: 100%;
            }
        }
    }
    .gallery-controls-inner {
        width: 5.5rem;
        height: 5.5rem;
        border-radius: 100%;
        border: 2px solid #fff;
        border: 2px solid var(--color-reverse);
        position: relative;
        @extend %transition;
        display: none;

        @include breakpoint(XS) {
            display: none;
        }
        &:before {
            content: "";
            height: 2px;
            width: 4rem;
            right: 100%;
            background: #fff;
            background: var(--color-reverse);
            top: calc(50% - 1px);
            position: absolute;
        }
        .slideno {
            position: absolute;
            top: 1rem;
            left: 1rem;
        }
        .totalslides {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
        .line {
            position: absolute;
            height: 2px;
            width: 3.5rem;
            background: #fff;
            background: var(--color-reverse);
            bottom: 2.5rem;
            left: 1rem;
            transform: rotate(-45deg);
        }
    }
    &:hover {
        color: #fff;
        color: var(--color-reverse);

        .gallery-controls-inner {
            background: $color-gold;
        }
    }
}

.gallery-carousel {
    .slick-track {
        display: flex;
        align-items: center;
        padding: 1rem 0;
    }
    .gallery-carousel-slide {
        position: relative;
        min-height: 40em;
        max-height: 83vh;
        margin: 0 1.5rem;
        width: calc(100vw - 24rem);
        transform: scale(1);
        @extend %transition;

        // &.slick-active {
        //     max-height: 90vh;
        // }

        &.slick-center {
            transform: scale(1.05);
        }        

        @include breakpoint(S) {
            height: 24rem;
        }
        @include breakpoint(XS) {
            height: 16rem;
            min-height: 1px;
            width: calc(100vw - 6rem);
            margin: 0 .5rem;

            &.slick-center {
                transform: scale(1);
            }
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: rgba(#000, 0.6);
            @extend %transition;
        }
        &.slick-active {
            &:before {
                opacity: 0;
            }
        }
        &.has-caption {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 81.25%, rgba(0, 0, 0, 0.75) 100%);
            }
        }
        .caption {
            position: absolute;
            bottom: 2.25rem;
            left: 0;
            width: 100%;
            padding: 0 1rem;
            text-align: center;
            z-index: 3;
            opacity: 0;
            @extend %nav-transition;
            transform: translateY(1rem);

            @include breakpoint(XS) {
                bottom: 0.875rem;
            }
        }
    }
    .slick-active {
        .caption {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .slick-arrow {
        top: 0;
        transform: none;
        height: 100%;
        width: 30%;
        padding: 0;
        display: flex !important;
        align-items: center;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            @extend %transition;
        }
        svg {
            position: relative;
            z-index: 2;
        }
        &.slider-prev {
            left: 0;

            &:after {
                left: -3rem;
                background: linear-gradient(to right, rgba(0,0,0,0.1) 0%,rgba(125,185,232,0) 100%);
            }
            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
        &.slider-next {
            right: 0;
            justify-content: flex-end;

            &:after {
                right: -3rem;
                background: linear-gradient(to left, rgba(0,0,0,0.1) 0%,rgba(125,185,232,0) 100%);
            }
            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}