.img-content.accordion-element {

    &.no-img {
        align-items: flex-start;
        padding-top: 0;
    }

    .accordion-group {
        &.inactive {
            opacity: .5;
        }
        .accordion-content {
            .button {
                margin-left: 0;
            }
        }
        @include breakpoint(XS) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .content {

        .content-element-inner {

            &.no-img {
                width: 100%;
            }
        }

        &.no-img {
            padding: 0;
            padding-top: 1rem;
            position: sticky;
            top: 3rem;
        }
    }

    @include breakpoint(XS) {
        .content {
            order: 2;
            &.no-img {
                position: relative;
                order: 1;
            }
            h2, hr {

            }
            .accordion-title {
                text-align: left;
                .icon {
                    border: 1px solid $color-gold;
                }
                +p {
                    text-align: left;
                    padding-right: 3.5rem;
                }
            }
            .accordion-content {
                text-align: left;
            }
        }
        &.img {
            --color-body: #fff;
            margin-bottom: 3rem;
            .content {
                padding-right: 2rem;
                padding-left: 2rem;
                padding-bottom: 0;
                padding-top: 3rem;
                @include breakpoint(XS) {
                    padding-left: 0;
                    padding-right: 0;
                }
                h2, hr {
                    margin-left: 2rem;
                    text-align: left;
                }                
                hr {
                    border-color: $color-white;
                }
                .accordion-title {
                    h4 {
                        color: $color-white;
                        font-size: 14px;
                        font-size: 1rem;
                    }
                    .icon {
                        border: 1px solid $color-white;
                        width: 2.5rem;
                        height: 2.5rem;
                        svg {
                            width: 10px;
                            path {
                                fill: $color-white;
                            }
                        }
                    }
                }
            }
            .img {
                display: none;
            }
        }
    }

    .img-bg {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            height: 100%;
            max-height: 100vh;
            position: sticky;
            top: 0;
        }
        
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.75);
            z-index: 1;            
        }

        @include breakpoint(XS) {
            display: block;
        }
    }
}