.post-highlight {
    @include breakpoint(S) {
        display: block;
    }
    .posts-content {
        width: 33.333%;
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include breakpoint(S) {
            width: 100%;
            text-align: center;

            .buttons {
                justify-content: center;
            }
            hr {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .posts-blocks {
        width: 66.666%;

        @include breakpoint(S) {
            width: 100%;
        }
    }
    &.text-posts {
        .posts-content {
            padding-right: 4rem;

            @include breakpoint(S) {
                padding-right: 0;
            }
        }
    }
    &.posts-text {
        .posts-content {
            padding-left: 4rem;
            order: 2;

            @include breakpoint(S) {
                padding-left: 0;
            }
        }
        .posts-blocks {
            order: 1;
        }
    }
}